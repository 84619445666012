<div class="container-fluid fixed top-0 z-20 w-full items-center justify-between bg-white p-3 shadow-md">
  <div class="row justify-content-end">
    <div class="col">
      <a [routerLink]="''">
        <img src="assets/img/area_logo2.webp" class="img-fluid" alt="azero logo" [routerLink]="'/home'" width="180"
             height="80">
      </a>
    </div>
    <div class="col-auto d-flex align-items-center">
      <div>
        <a href="" class="btn header_link">Contatti</a>
        <span class="vr"></span>
        <a href="" class="btn header_link">Manuali</a>
      </div>
    </div>
    <div class="col-auto d-flex align-items-center">
      <button *ngIf="authServ.isAuthenticated$ | async; else accediTpl"
              class="inline-flex items-center justify-center whitespace-nowrap font-semibold transition-colors focus-visible:outline-none bg-primary text-primary-foreground shadow rounded-full p-4 text-base h-10 w-18 py-2"
              [routerLink]="'/logout'">Esci
      </button>
			<ng-template #accediTpl>
				<button
              class="inline-flex items-center justify-center whitespace-nowrap font-semibold transition-colors focus-visible:outline-none bg-primary text-primary-foreground shadow rounded-full p-4 text-base h-10 w-28 py-2"
              [routerLink]="'/login'">Accedi
      </button>
			</ng-template>
    </div>
  </div>
</div>
