/**
 * Classe contenente tutti le impostazioni di base dell'applicazione.
 */
export class AppSettings {

  public static APPLICAZIONE= "Geolocation"
  /**
   * Prefisso riservato per l'accesso alla REST api.
   */
  public static API_ENDPOINT = 'api';
  /**
   * Url per effettuare il POST della richiesta di login
   */
  public static LOGIN_ENDPOINT = AppSettings.API_ENDPOINT + '/login';
  /**
   * Nome dell'header che contiene i valori del token di autenticazione JWT.
   */
  public static AUTHORIZATION_HEADER_NAME = 'Authorization';
  /**
   * Nome dell'header della lingua.
   */
  public static ACCEPT_LANGUAGE_HEADER_NAME = 'Accept-Language';
  /**
   * Nome dell'header che contiene il cookie di autenticazione.
   */
  public static AUTHORIZATION_COOKIE_NAME = 'AUTH_JWT_COOKIE';
  /**
   * Nome della variabile del localStorage in cui viene conservato il token JWT.
   */
  public static JWT_TOKEN_LOCALSTORE_KEY = 'Geolocation-Token';
  /**
   * Nome della variabile del localStorage in cui viene conservato l'oggetto che descrive l'utente autenticato.
   */
  public static CURRENT_USER_LOCALSTORE_KEY = 'Geolocation-CurrentUser';
  /**
   * Nome dell'applicazione.
   */
  public static APP_NAME = 'Geolocation';
  /**
   * Numero massimo di filtri di ricerca memorizzati nel LocalStorage del browser
   */
  public static MAX_SEARCH_FILTERS_HISTORY = 5;
  /**
   * Numero di elementi di default per pagina di ricerca
   */
  public static DEFAULT_PAGE_RESULTS = 10;
  /**
   * Url per effettuare il login tramite CAS (attiva il redirect sul portale del CAS)
   */
  public static CAS_LOGIN_ENDPOINT = '/cas/login';
   /**
   * Url per effettuare il logout tramite CAS (attiva il global logout)
   */
  public static CAS_LOGOUT_ENDPOINT = '/cas/logout';
  /**
   * Url per effettuare il login tramite CAS (attiva il CasAuthenticationFilter lato server)
   */
  public static CAS_LOGIN_FILTER = '/cas/check-the-cas';
  /**
   * Url per attivare il casComponent.ts lato client
   */
  public static CAS_LOGIN_FILTER_CLIENT = 'check-the-cas-client';


}
