import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ParamMap } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
//import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';

import { AppSettings } from '../../app.settings';
import { ResponsePage } from '../models/response-page';
import { environment } from '../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import {Permesso} from "../models/area.model";

/**
 * Service di autenticazione
 */
@Injectable({providedIn: 'root'})
export class AuthService {

  constructor(
    private http: HttpClient,
//    private cookieService: CookieService,
//    @Inject(DOCUMENT) private document
	){}

  /**
   * Controlla se l'utente è autenticato.
   */
  public isAuthenticated(): boolean {
    return (localStorage.getItem(AppSettings.JWT_TOKEN_LOCALSTORE_KEY)
      && JSON.parse(localStorage.getItem(AppSettings.CURRENT_USER_LOCALSTORE_KEY)!));
  }
	
	public isAuthenticated$ = new BehaviorSubject(this.isAuthenticated());

  /**
   * Effettua la login.
   * @param username
   * @param password
   * @param urlParams
   */
  public login(username: string, password: string, urlParams: ParamMap): Observable<boolean> {
    let params = '';
    if (urlParams) {
      for (const entry of urlParams.keys) {
        if (entry) {
          params += ((params === '') ? '?' : '&') + entry + '=' + urlParams.get(entry);
        }
      }
    }
    return this.http
      .post<ResponsePage<any>>(
        AppSettings.LOGIN_ENDPOINT + params,
        JSON.stringify({'username': username, 'password': password}),
        {observe: 'response'}
      )
      .pipe(
				tap(() => this.isAuthenticated$.next(true)),
        map((response: HttpResponse<ResponsePage<any>>) => {
          // login successful if there's a jwt token in the response
          const pageResponse = response.body;
          // console.log(pageResponse);

          // TODO caricamento di un eventuale tema personalizzato o analisi di altri attributi ricevuti tramite response

          const token = response.headers.get(AppSettings.AUTHORIZATION_HEADER_NAME);
          localStorage.setItem(AppSettings.JWT_TOKEN_LOCALSTORE_KEY, token!);

          // Aggiunta del token JWT anche a livello di cookie
//          this.cookieService.set(
//            AppSettings.AUTHORIZATION_COOKIE_NAME,
//            token,
//            1,
//            environment.href_base_url + '/',
//            document.location.hostname,
//            false
//          );

          // TODO le info dell'operatore potrebbero essere codificate in qualche modo per evitare che le possa vedere da strumenti
          // di sviluppo del browser
          localStorage.setItem(AppSettings.CURRENT_USER_LOCALSTORE_KEY, JSON.stringify(pageResponse?.content));
          return true;
        }),
      )
  }

  /**
   * Effettua il logout.
   */
  public logout(): void {
    // clear token remove user from local storage to log user out
    localStorage.removeItem(AppSettings.CURRENT_USER_LOCALSTORE_KEY);
    localStorage.removeItem(AppSettings.JWT_TOKEN_LOCALSTORE_KEY);
		tap(() => this.isAuthenticated$.next(false))


//    this.cookieService.deleteAll();
  }

  applicazione = AppSettings.APPLICAZIONE

  public checkPermesso(permessi: Permesso[], enummer: any[]): boolean {
    if (permessi) {
      for (const permesso of permessi) {
        if (permesso.applicazione?.nome == this.applicazione && permesso.enumValue != null) {
          for (const enumm of enummer) {
            if (permesso.enumValue == enumm) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

}
