import { AppSettings } from './../../app.settings';

/**
 * Classe di utils relativa all'utente.
 */
export default class UserInfoUtils {

  /**
   * Ritorna l'utente corrente (recuperandolo dal local storage)
   */
  static getUserInfo(): any {
    return JSON.parse(localStorage.getItem(AppSettings.CURRENT_USER_LOCALSTORE_KEY)!);
  }

}
