import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {Observable, of} from 'rxjs';
import {Router, RouterLink} from '@angular/router';
import UserInfoUtils from "../../shared/utils/user-info.utils";

@Component({
  selector: 'app-details',
  standalone: true,
  imports: [CommonModule, NgbNavModule, RouterLink],
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  item: { name: string, desc: string; desc_ext: string; class: string; url: string };
  explanation = '';
  screenshots$: Observable<Array<string>>;
  data$: Observable<Array<string>>;
  userInfo: any = {};
  active = 1;

  constructor(private router: Router) {
    this.item = this.router.getCurrentNavigation()?.extras?.state?.['app'];
    this.screenshots$ = of(['', '', '']);
    this.data$ = of(['Dati', 'Altri Dati', 'Basta Dati'])
  }

  ngOnInit() {
    this.userInfo = UserInfoUtils.getUserInfo();
  }
}
