import { Component } from "@angular/core";
import { RouterLink, RouterOutlet } from "@angular/router";
import { HeaderNewComponent } from "./layout/header-new/header-new.component";
import {FooterNewComponent} from "./layout/footer-new/footer-new.component";


@Component({
	standalone: true,
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
  imports: [RouterLink, RouterOutlet, HeaderNewComponent, FooterNewComponent]
})
export class AppComponent {
	title = 'area-geolocation';



}
