import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SpinnerService {

	public loading$: Observable<boolean>;
	private _loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private count = 0;

	public constructor() {
		this.loading$ = this._loading$.asObservable();
	}

	show() {
		if (this.count++ === 0)
			this._loading$.next(true);
	}

	hide() {
		if (--this.count === 0)
			this._loading$.next(false)
	}
}
