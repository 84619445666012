import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {AppSettings} from '../app.settings';
import {environment} from '../../environments/environment';
import {Page} from '../shared/models/page';
import {map} from 'rxjs/operators';
import UserInfoUtils from '../shared/utils/user-info.utils';
import {AuthService} from '../shared/services/auth.service';

@Component({
  selector: 'app-cas',
  templateUrl: './cas.component.html',
  styleUrls: ['./cas.component.scss']
})
export class CasComponent implements OnInit {

  CAS_LOGIN_FILTER_CLIENT = '';
  CAS_LOGIN_FILTER_ENDPOINT = '';

  private userInfo: any = {};

  constructor(private http: HttpClient,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private authService: AuthService) {
  }

  ngOnInit() {
    const inputData = this.activatedRoute.snapshot.data;
    this.CAS_LOGIN_FILTER_CLIENT = inputData['CAS_LOGIN_FILTER_CLIENT'];
    this.CAS_LOGIN_FILTER_ENDPOINT = inputData['CAS_LOGIN_FILTER_ENDPOINT'];

    console.log('this.CAS_LOGIN_FILTER_CLIENT: ', this.CAS_LOGIN_FILTER_CLIENT);
    console.log('this.CAS_LOGIN_FILTER_ENDPOINT: ', this.CAS_LOGIN_FILTER_ENDPOINT);


    const url = this.activatedRoute.snapshot.toString();
    console.log(url);

    if (url.indexOf(this.CAS_LOGIN_FILTER_CLIENT) !== -1) {
      console.log(this.CAS_LOGIN_FILTER_CLIENT);
      this.checkTheCas();
    } else if (url.indexOf('logout') !== -1) {
      console.log('logout');
      this.logout();
    }
  }

  private checkTheCas(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const ticket = params['ticket'];
      const qParams = new HttpParams().set('ticket', ticket);

      this.http
        .get<Page<any>>(
          this.CAS_LOGIN_FILTER_ENDPOINT,
          {params: qParams, observe: 'response'}
        )
        .pipe(
          map((response) => {
            // login successful if there's a jwt token in the response
            const pageResponse = response.body;
            console.log('checkTheCas: ' + pageResponse);

            const token = response.headers.get(AppSettings.AUTHORIZATION_HEADER_NAME);
            localStorage.setItem(AppSettings.JWT_TOKEN_LOCALSTORE_KEY, token!);

            // Aggiunta del token JWT anche a livello di cookie
//            this.cookieService.set(
//              AppSettings.AUTHORIZATION_COOKIE_NAME,
//              token,
//              1,
//              environment.href_base_url + '/',
//              document.location.hostname,
//              false
//            );

            // TODO le info dell'operatore potrebbero essere codificate in qualche modo per evitare che le possa vedere da strumenti
            // di sviluppo del browser
            console.log('checkTheCas: ' + JSON.stringify(pageResponse!.content));
            localStorage.setItem(AppSettings.CURRENT_USER_LOCALSTORE_KEY, JSON.stringify(pageResponse!.content));
            this.userInfo = UserInfoUtils.getUserInfo();
            return true;
          })
        )
        .subscribe(
          result => {
            if (result === true) {
              if (this.userInfo &&
                (this.userInfo.listaPermessi?.some((permesso: any) => permesso.applicazione?.nome === AppSettings.APPLICAZIONE))
              ) {
								this.authService.isAuthenticated$.next(true)
                this.router.navigate(['/home']);
              } else {
                this.router.navigate(['/access-denied']);
              }
            } else {
              console.error('Error occured on login process...');
            }
          },
          error => {
            console.error('Error occured on login process... status = ' + ((error.status) ? error.status : 'undefined'));
          }
        );
    });
  }

  private logout(): void {
    this.authService.logout();
    window.location.href = environment.CAS_LOGOUT_ENDPOINT;
  }

}
