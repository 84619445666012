import { AppSettings } from "src/app/app.settings";

const SERVER_URL = 'https://area-geolocation.3di.it/areaGeolocation';
/**
 * Variabile di ambiente di produzione.
 */
export const environment = {
	debug: false,
	production: true,
	href_base_url: '/areaGeolocation',
	api_url: 'api',


  CAS_LOGIN_ENDPOINT: `${SERVER_URL}${AppSettings.CAS_LOGIN_ENDPOINT}`,
  CAS_LOGOUT_ENDPOINT: `${SERVER_URL}${AppSettings.CAS_LOGOUT_ENDPOINT}`,
  CAS_LOGIN_FILTER_ENDPOINT: `${SERVER_URL}${AppSettings.CAS_LOGIN_FILTER}`,
  CAS_LOGIN_FILTER_CLIENT: `${AppSettings.CAS_LOGIN_FILTER_CLIENT}`, /*    check-the-cas-client    */
  
  };
