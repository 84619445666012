import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLink} from '@angular/router';
import UserInfoUtils from "../../shared/utils/user-info.utils";

@Component({
  selector: 'app-portal',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit {
  userInfo: any = {};
  appsData: Array<{ name: string, desc: string; desc_ext: string; class: string; url: string }> = [
    {
      name: 'Autorizzazione Rete Assistenziale',
      desc: 'AReA',
      desc_ext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et, eleifend felis. Donec ut ligula sed libero ultrices.",
      class: 'bg-area',
      url: 'https://sanregven.demo.3di.it/amministrazione/'
    },
    {
      name: 'Piattaforma Organigrammi Aziendali',
      desc: 'POA',
      desc_ext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et, eleifend felis. Donec ut ligula sed libero ultrices.",
      class: 'bg-poa',
      url: 'https://sanregven.demo.3di.it/organigrammi/'
    },
    {
      name: 'Cronos',
      desc: 'Programmazione',
      desc_ext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et, eleifend felis. Donec ut ligula sed libero ultrices.",
      class: 'bg-cronos',
      url: 'https://sanregven.demo.3di.it/area/programmazione/find'
    },
    {
      name: 'Prescrittori Piani Terapeutici',
      desc: 'prePSF',
      desc_ext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et, eleifend felis. Donec ut ligula sed libero ultrices.",
      class: 'bg-prepsf',
      url: 'https://sanregven.demo.3di.it/centriprescrittori/login'
    },
    {
      name: 'Repository Planimetrie',
      desc: 'Replan',
      desc_ext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et, eleifend felis. Donec ut ligula sed libero ultrices.",
      class: 'bg-replan',
      url: 'https://sanregven.demo.3di.it/amministrazione/'
    },
		{
			name: 'Autorizzazioni',
			desc: 'Autorizzazioni',
			desc_ext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et, eleifend felis. Donec ut ligula sed libero ultrices.",
			class: 'bg-map',
			url: ''
		},
		{
			name: 'Micrologistica e Centri di Costo',
			desc: 'Argo',
			desc_ext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et, eleifend felis. Donec ut ligula sed libero ultrices.",
			class: 'bg-map',
			url: ''
		},
    {
      name: 'Mappa dei servizi',
      desc: 'Mappa',
      desc_ext: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, molestie ipsum et, eleifend felis. Donec ut ligula sed libero ultrices.",
      class: 'bg-map',
      url: ''
    },
  ]

  ngOnInit() {
    this.userInfo = UserInfoUtils.getUserInfo();
  }
}
