<!--<div class="container-fluid py-2 bg-primary bg-opacity-75">-->
<!--	<div class="row justify-content-center">-->
<!--		<div class="col-2 d-flex align-items-center">-->
<!--			<img src="assets/img/logo-regione-veneto-full.png" class="img-fluid" alt="regione veneto logo">-->
<!--		</div>-->
<!--	</div>-->
<!--</div>-->
<footer class="z-30 flex items-center justify-between bg-slate-200 px-4 py-6">
  <img alt="3D Informatica logo"
       loading="lazy" width="240"
       height="240" decoding="async"
       data-nimg="1"
       style="color:transparent"
       src="assets/img/logo-regione-veneto-full.png">
  <section class="flex items-center space-x-2"><a class="footer_link" href="">Privacy</a>
    <div data-orientation="vertical" role="none" class="shrink-0 h-4 w-[1.2px] bg-black opacity-45"></div>
    <a class="footer_link" href="">Note legali</a></section>
</footer>
