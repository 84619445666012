<div class="container-fluid mt-[96Px] w-full px-0">
  <div style="height: 280px;">
    <img src="assets/img/doctor.webp" class="h-100 w-100 object-fit-cover" alt="azero logo">
  </div>
</div>
<p class="pt-8 text-center text-lg font-medium mb-0">Ecosistema</p>
<div class="row p-10 pt-4 mx-0">
  <div *ngFor="let app of appsData" class="col-3 gy-4">
    <div class="card">
      <div class="card-header text-white {{app.class}}">
        <h5>{{ app.name }}</h5>
        <p class="opacity-50">{{ app.desc }}</p>
      </div>
      <div class="card-body overflow-hidden rounded-md bg-white shadow-md hover:shadow-xl">
        {{ app.desc_ext }}
        <div class="row">
          <div class="col gy-4 d-flex justify-content-center">
            <a [href]="app.url" [ngClass]="userInfo===null ? 'disabled' : ''"
               class="btn bg-replan flex-1 font-semibold inline-flex items-center justify-center p-2 rounded-full shadow text-primary-foreground text-sm transition-colors whitespace-nowrap {{app.class}}">Apri</a>
          </div>
          <div class="col gy-4 d-flex justify-content-center">
            <a [routerLink]="'details/'" [state]="{ app: app }"
               class="inline-flex items-center justify-center whitespace-nowrap font-semibold transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border-[1.6px] border-primary bg-background shadow-sm hover:bg-accent hover:text-accent-foreground rounded-full p-2 flex-1 text-sm">Info</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
