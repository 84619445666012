import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RouterLink} from '@angular/router';
import UserInfoUtils from "../../shared/utils/user-info.utils";
import {DomUtil} from "leaflet";
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-header-new',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, RouterLink],
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.scss']
})
export class HeaderNewComponent implements OnInit {
  userInfo: any = {};

  constructor(protected authServ: AuthService) {
    this.userInfo = UserInfoUtils.getUserInfo();
    console.log(this.userInfo);
  }

  ngOnInit() {
    this.userInfo = UserInfoUtils.getUserInfo();
  }

}
