/// <reference types="@angular/localize" />

import {bootstrapApplication} from '@angular/platform-browser';
import {AppComponent} from './app/app.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi
} from '@angular/common/http';
import {Routes, provideRouter} from '@angular/router';
import {provideAnimations} from '@angular/platform-browser/animations';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {importProvidersFrom} from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {environment} from './environments/environment';
import {CasComponent} from './app/cas/cas.component';
import {PermessiProgrammazioneEnum} from './app/shared/models/area-model-ext';
import {AuthGuard} from './app/shared/guards/auth.guard';
import {HomeComponent} from './app/layout/home/home.component';
import {AuthorizationInterceptor} from './app/shared/services/auth-interceptor.service';
import {PortalComponent} from './app/layout/portal/portal.component';
import {DetailsComponent} from './app/layout/details/details.component';

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {
    //	path: 'home', loadComponent: () => HomeComponent,
    //		canActivate:  [AuthGuard], data: { roles: [PermessiProgrammazioneEnum.AMMINISTRATORE] }
    path: 'home', loadComponent: () => PortalComponent,
  },
  {path: 'login', loadChildren: () => import('./app/login/login.module').then(m => m.LoginModule)},
  {path: 'home/details', loadComponent: () => DetailsComponent},
  {
    path: environment.CAS_LOGIN_FILTER_CLIENT,
    component: CasComponent,
    data: {
      CAS_LOGIN_FILTER_CLIENT: environment.CAS_LOGIN_FILTER_CLIENT,
      CAS_LOGIN_FILTER_ENDPOINT: environment.CAS_LOGIN_FILTER_ENDPOINT
    }
  },
  {path: 'logout', component: CasComponent},

];

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(
      withInterceptorsFromDi()),
    {provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true},
    provideRouter(routes),
    provideAnimations(),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
        }
      })
    )
  ]
}).catch(e => console.error(e));
