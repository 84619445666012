import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';

import { AppSettings } from '../../app.settings';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from 'src/app/spinner.service';

// Guida: https://blog.hackages.io/angular-http-httpclient-same-but-different-86a50bbcc450
/**
 * Service di intercettazione delle chiamata HTTP per la gestione della loadingbar e dei token JWT.
 */
@Injectable({providedIn: 'root'})
export class AuthorizationInterceptor implements HttpInterceptor {
  lastKey: number;
  maxLastKey: number;
  backgrounActive: Set<number>;

  constructor(
    private spinnerService: SpinnerService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.backgrounActive = new Set();
    this.lastKey = 0;
    this.maxLastKey = 10000;
  }

  /**
   * Intercept an outgoing `HttpRequest` and optionally transform it or the
   * response.
   *
   * Typically an interceptor will transform the outgoing request before returning
   * `next.handle(transformedReq)`. An interceptor may choose to transform the
   * response event stream as well, by applying additional Rx operators on the stream
   * returned by `next.handle()`.
   *
   * More rarely, an interceptor may choose to completely handle the request itself,
   * and compose a new event stream instead of invoking `next.handle()`. This is
   * acceptable behavior, but keep in mind further interceptors will be skipped entirely.
   *
   * It is also rare but valid for an interceptor to return multiple responses on the
   * event stream for a single request.
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const background = this.isBackground(req);
    // console.log('intercept - background: ' + background + '; intercept - url: ' + req.url);
    if (!background) {
      this.spinnerService.show();
    }
    return next.handle(this.addAuthorizationHeader(req))
      .pipe(
      tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
              if (!background) {
                   this.spinnerService.hide();
              }
            }
          }, (error: any) => {
            console.error('intercept error: ' + JSON.stringify(error));
            if (!background) {
               this.spinnerService.hide();
            }
            return this.showErrorDetails(error);
          }
        ));
  }

  private nextKey() {
    if (this.lastKey >= this.maxLastKey) {
      this.lastKey = 0;
    }
    this.lastKey++;
    return this.lastKey;
  }

  /**
   * Controlla negli header se è stato richiesta la chiamata senza loadingbar (background = true).
   * @param req
   */
  private isBackground(req: HttpRequest<any>): boolean {
    return req.headers.get('background') === 'true';
  }

  /**
   * Aggiunta del token JWT di autenticazione all'header della richiesta HTTP
   * @param req
   */
  private addAuthorizationHeader(req: HttpRequest<any>): HttpRequest<any> {
    if (req.url.startsWith(AppSettings.API_ENDPOINT + '/')) {
      // richiesta verso API, richiede il token autoritativo
      const token = localStorage.getItem(AppSettings.JWT_TOKEN_LOCALSTORE_KEY);
      if (token) {
        // console.log('Append token "' + token + '" to request header...');
        return req.clone({
          setHeaders: { [AppSettings.AUTHORIZATION_HEADER_NAME]: token }
        });
      }
    }
    return req;
  }

  /**
   * Aggiunta del del header accept-language della lingua correntemente selezionata all'header della richiesta HTTP
   * @param req
   */
  private addAcceptLanguageHeader(req: HttpRequest<any>): HttpRequest<any> {
    if (req.url.startsWith(AppSettings.API_ENDPOINT + '/')) {
      // richiesta verso API, rimuovo Accept-Language e aggiungo la lingua corrente
      console.log('this.translate.currentLang: ' + this.translate.currentLang);
      console.log('req.headers: ' + req.headers.keys());
      let httpHeaders: HttpHeaders = req.headers.delete(AppSettings.ACCEPT_LANGUAGE_HEADER_NAME);
      httpHeaders = httpHeaders.append(AppSettings.ACCEPT_LANGUAGE_HEADER_NAME, this.translate.currentLang);
      console.log('httpHeaders: ' + httpHeaders.keys());
      return req.clone({
        headers: httpHeaders
      });
    }
    return req;
  }


  /**
   * Visualizzazione dei dettagli di un eventuale errore ricevuto dalla chiamata HTTP
   * @param err
   */
  private showErrorDetails(err: HttpErrorResponse): Observable<any> {
    // console.log('showErrorDetails: ' + err.url);
    if (err && err.url?.indexOf(AppSettings.LOGIN_ENDPOINT) === -1) { // errore da intercettare solo se non si tratta di un login
      let jsonbody;
      try {
        if (err.error) {
          if (typeof err.error === 'string') {
            jsonbody = { message: err.error };
          } else {
            jsonbody = err.error;
          }
        }
      } catch (e) {
        console.error(e);
        jsonbody = {
          message: 'Unable to parse response body',
          description: 'Possibile problema di connessione con il server',
          level: 'FATAL',
          stacktrace: e
        };
      }
      console.log('err.status: ' + err.status + '; jsonbody: ' + JSON.stringify(jsonbody));
      if (err.status === 409) {
        console.log(err.status + jsonbody);
      } else {
        console.log(err.status + jsonbody);
      }
    }
    return throwError(err);
  }
}
