<div class="container-fluid mt-[96Px] w-full px-0">
	<div class="h-100">
		<section class="p-3 text-white {{ item.class }} ">
			<h4 class="mb-0">{{ item.name }}</h4>
			<p class="opacity-50 mb-0">{{ item.desc }}</p>
		</section>
		<section class="h-full space-x-4 p-3 md:flex lg:flex xl:flex">
			<div class="flex-1">

				<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs border-purple flex w-full space-x-2 border-b">
					<li [ngbNavItem]="1">
						<button ngbNavLink class="px-8 py-2 text-sm cursor-pointer hover:bg-slate-100 ">Descrizione</button>
						<ng-template ngbNavContent>
							<div [ngSwitch]="item.desc">
								<ng-container *ngSwitchCase="'AReA'">
									<ng-container *ngTemplateOutlet="areaDescTpl"></ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'POA'">
									<ng-container *ngTemplateOutlet="poaDescTpl"></ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'Programmazione'">
									<ng-container *ngTemplateOutlet="programmazioneDescTpl"></ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'prePSF'">
									<ng-container *ngTemplateOutlet="prepsfDescTpl"></ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'Autorizzazioni'">
									<ng-container *ngTemplateOutlet="autorizzazioniDescTpl"></ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'Argo'">
									<ng-container *ngTemplateOutlet="argoDescTpl"></ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'Replan'">
									<ng-container *ngTemplateOutlet="replanDescTpl"></ng-container>
								</ng-container>
								<ng-container *ngSwitchCase="'Mappa'">
									<ng-container *ngTemplateOutlet="mappaDescTpl"></ng-container>
								</ng-container>
							</div>
						</ng-template>
					</li>
					<li [ngbNavItem]="2">
						<button ngbNavLink class="px-8 py-2 text-sm cursor-pointer hover:bg-slate-100 ">Screenshot</button>
						<ng-template ngbNavContent>
							<div class="grid w-full gap-4 space-y-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
								<img alt="screenshot_0" decoding="async" data-nimg="1" class="h-40 w-full bg-slate-200 p-2" src=""
									style="">
								<img alt="screenshot_1" decoding="async" data-nimg="1" class="h-40 w-full bg-slate-200 p-2" src=""
									style="">
								<img alt="screenshot_2" decoding="async" data-nimg="1" class="h-40 w-full bg-slate-200 p-2" src=""
									style="">
								<img alt="screenshot_3" decoding="async" data-nimg="1" class="h-40 w-full bg-slate-200 p-2" src=""
									style="">
								<img alt="screenshot_4" decoding="async" data-nimg="1" class="h-40 w-full bg-slate-200 p-2" src=""
									style="">
							</div>
						</ng-template>
					</li>
					<li [ngbNavItem]="3">
						<button ngbNavLink class="px-8 py-2 text-sm cursor-pointer hover:bg-slate-100 ">Dati</button>
						<ng-template ngbNavContent>
							<p>Dati</p>
						</ng-template>
					</li>
				</ul>

				<div [ngbNavOutlet]="nav" class="mt-2"></div>

			</div>
			<div class="h-full w-[28%] space-y-4">
				<section class="mb-4">
					<section class="flex items-center justify-between border-b p-2">
						<p class="text-sm">Versione</p>
						<p class="text-sm font-medium">Versione</p>
					</section>
					<section class="flex items-center justify-between border-b p-2">
						<p class="text-sm">Data di rilascio</p>
						<p class="text-sm font-medium">Data di rilascio</p>
					</section>
					<section class="flex items-center justify-between border-b p-2">
						<p class="text-sm">Ultimo aggiornamento</p>
						<p class="text-sm font-medium">Ultimo aggiornamento</p>
					</section>
				</section>
				<button [routerLink]="'/logout'" *ngIf="userInfo===null"
					class="inline-flex items-center justify-center whitespace-nowrap font-semibold transition-colors bg-primary text-primary-foreground shadow rounded-full text-base h-16 w-full p-4">
					Accedi
				</button>
				<a [href]="item.url" *ngIf="userInfo!==null"
					class="inline-flex items-center justify-center whitespace-nowrap font-semibold transition-colors bg-primary text-primary-foreground shadow rounded-full text-base h-16 w-full p-4">
					Apri
				</a>
				<a style="border: 1px solid black !important;"
					class="inline-flex items-center justify-center whitespace-nowrap font-semibold transition-colors border-[1.6px] bg-background shadow-sm rounded-full p-3 text-base w-full border-black"
					href="">
					<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="mr-2" height="20"
						width="20" xmlns="http://www.w3.org/2000/svg">
						<path fill="none" d="M0 0h24v24H0z"></path>
						<path
							d="M21 5c-1.11-.35-2.33-.5-3.5-.5-1.95 0-4.05.4-5.5 1.5-1.45-1.1-3.55-1.5-5.5-1.5S2.45 4.9 1 6v14.65c0 .25.25.5.5.5.1 0 .15-.05.25-.05C3.1 20.45 5.05 20 6.5 20c1.95 0 4.05.4 5.5 1.5 1.35-.85 3.8-1.5 5.5-1.5 1.65 0 3.35.3 4.75 1.05.1.05.15.05.25.05.25 0 .5-.25.5-.5V6c-.6-.45-1.25-.75-2-1zm0 13.5c-1.1-.35-2.3-.5-3.5-.5-1.7 0-4.15.65-5.5 1.5V8c1.35-.85 3.8-1.5 5.5-1.5 1.2 0 2.4.15 3.5.5v11.5z">
						</path>
						<path
							d="M17.5 10.5c.88 0 1.73.09 2.5.26V9.24c-.79-.15-1.64-.24-2.5-.24-1.7 0-3.24.29-4.5.83v1.66c1.13-.64 2.7-.99 4.5-.99zM13 12.49v1.66c1.13-.64 2.7-.99 4.5-.99.88 0 1.73.09 2.5.26V11.9c-.79-.15-1.64-.24-2.5-.24-1.7 0-3.24.3-4.5.83zM17.5 14.33c-1.7 0-3.24.29-4.5.83v1.66c1.13-.64 2.7-.99 4.5-.99.88 0 1.73.09 2.5.26v-1.52c-.79-.16-1.64-.24-2.5-.24z">
						</path>
					</svg>
					Manuale</a>
				<a style="border: 1px solid black !important;"
					class="inline-flex items-center justify-center whitespace-nowrap font-semibold transition-colors border-[1.6px] bg-background shadow-sm rounded-full p-3 text-base w-full border-black"
					href="">
					<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="mr-2" height="20"
						width="20" xmlns="http://www.w3.org/2000/svg">
						<path fill="none" d="M0 0h24v24H0z"></path>
						<path d="M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z"></path>
					</svg>
					Scarica open data</a>
			</div>
		</section>
	</div>
</div>

<ng-template #areaDescTpl>
	<p>AReA &egrave; un insieme di anagrafiche collegate tra loro per mezzo delle quali sono censite, classificate e
		descritte le strutture che erogano attivit&agrave; sanitarie, sociosanitarie e sociali di Regione Veneto.</p><br>
	<p>L&rsquo;anagrafica gestisce dinamicamente, a partire dai dati caricati dai titolari delle strutture:</p><br>
	<ul class="bullet-disc">
		<li>
			<p>le attivit&agrave; erogate, definite <span class="text-highlight">Unit&agrave; di Offerta</span>, corredate dai
				descrittori previsti dal <span class="text-highlight">flusso MRA</span>;</p><br>
		</li>
		<li>
			<p>la georeferenziazione dei <span class="text-highlight">punti fisici </span>(PF) corrispondenti alle strutture
				dove avviene l&rsquo;erogazione, per distretto e Azienda Ulss competente per territorio;</p><br>
		</li>
		<li>
			<p>la codifica degli Edifici presenti nelle Strutture Fisiche e degli indirizzi di accesso (Sedi Operative)</p>
			<br>
		</li>
		<li>
			<p>i dati dei <span class="text-highlight">titolari </span>che in quelle sedi hanno collocato la loro offerta di
				servizi.</p><br>
		</li>
		<li>
			<p>gli estremi dei provvedimenti di Autorizzazione-Accreditamento per le attivit&agrave; soggette alla LR 22/2002.
			</p><br>
		</li>
	</ul>
	<p>Tramite integrazione con la <span class="text-highlight">Piattaforma Organigrammi Aziendali </span>(POA) AReA
		riconduce le attivit&agrave; erogate ai responsabili aziendali delle stesse e all&rsquo;insieme dei collaboratori
		che compongono l&rsquo;Unit&agrave; Operativa (o termine equivalente per il privato).</p><br>
	<p>AReA &egrave; l&rsquo;anagrafica di riferimento per il <span class="text-highlight">Sistema Informativo
			Ospedaliero</span>, per i procedimenti di <span class="text-highlight">Autorizzazione e di Accreditamento</span>,
		per il <span class="text-highlight">Monitoraggio della Rete Assistenziale (MRA</span>) a livello regionale e
		nazionale (Ministero della Salute, Agenas).</p><br>
	<p>Una sezione specifica di AReA &egrave; dedicata all&rsquo;anagrafica delle attivit&agrave; dei Medici di Medicina
		Generale e dei Pediatri di Libera Scelta.</p><br>
	<p>In AReA sono censiti i <span class="text-highlight">Centri Regionali Specializzati </span>e sono tracciate le <span
			class="text-highlight">Reti </span>regionali.</p><br>
	<p>In AReA si stanno progressivamente inserendo anche le Unit&agrave; di Offerta di natura amministrativo-gestionale.
	</p><br>
	<p>Tramite integrazione con la Piattaforma Organigrammi Aziendali e con l&rsquo;Anagrafe del personale AReA supporta
		il processo di <span class="text-highlight">Autorizzazione dei Prescrittori di Piani Terapeutici, </span>esprimendo
		<span class="text-highlight">l&rsquo;</span>applicazione delle direttive di AIFA all&rsquo;organizzazione regionale.
	</p><br>
	<p>Le informazioni disponibili in AReA di interesse per i cittadini sono rese disponibili tramite il <span
			class="text-highlight">Portale Sanit&agrave; del Cittadino </span>per la ricerca, l&rsquo;accesso, i contatti con
		le strutture.</p><br>
	<p><span class="text-danger">ACCEDI ad AReA (operatori) (soggetti autorizzati/accreditati da Regione Veneto)</span>
	</p><br>
</ng-template>

<ng-template #poaDescTpl>
	<p>L&rsquo;organigramma &egrave; uno strumento
		gestionale utile ad evidenziare ruoli e legami, gerarchici e funzionali, che caratterizzano ciascun contesto in base
		al modello organizzativo adottato e, per le Aziende Sanitarie, approvato con Atto Aziendale.</p><br>
	<p>La Piattaforma Organigrammi Aziendali (POA)
		consente agli erogatori censiti in AReA di rappresentare l&rsquo;articolazione organizzativa interna delle
		responsabilit&agrave; e di collegare ai nodi organizzativi le attivit&agrave; erogate (Unit&agrave; di offerta
		inserite in AReA) e gli operatori coinvolti nell&rsquo;erogazione (Operatori inseriti nella sezione Personale di
		POA).</p><br>
	<p>Per le aziende sanitarie l&rsquo;inserimento
		dell&rsquo;organigramma aziendale &egrave; guidato da controlli automatici, in modo da condurre ad un assetto
		organizzativo flessibile ma nel rispetto della programmazione regionale, gestita in <span
			class="text-highlight">Cronos</span>. Per i restanti erogatori la struttura dell&rsquo;organigramma &egrave;
		facoltativa e discrezionale.</p><br>
	<p>L&rsquo;organigramma &egrave; funzionale alla
		gestione informatizzata del processo di Autorizzazione dei <span class="text-highlight">Prescrittori di Piani
			terapeutici (__)</span>, supportata dall&rsquo;interfaccia <span class="text-highlight">pre PSF, </span>ma
		l&rsquo;organigramma pu&ograve; essere utilizzato anche per elaborare alcuni allegati obbligatori previsti dal <span
			class="text-highlight">processo di Autorizzazione</span><span class="text-highlight">-Accreditamento.</span></p>
	<br>
	<p>&nbsp;</p><br>
	<p><span class="text-danger">ACCEDI a POA (operatori) (pubblico)</span></p><br>
</ng-template>

<ng-template #programmazioneDescTpl>
	<p>Cronos supporta l&rsquo;inserimento, la
		visualizzazione, l&rsquo;aggiornamento, la consultazione e l&rsquo;estrazione dei dati di programmazione e la
		produzione delle <span class="text-highlight">schede ospedaliere</span>.</p><br>
	<p>In Cronos ad oggi &egrave; presente la
		programmazione relativa alle strutture ospedaliere, alle strutture intermedie, alle strutture di salute mentale,
		alle strutture per le dipendenze, alle Case di Comunit&agrave;.</p><br>
	<p>La programmazione inserita in Cronos si traduce
		in regole di compilazione dell&rsquo;organigramma delle aziende sanitarie nella Piattaforma Organigrammi aziendali.
	</p><br>
	<p>L&rsquo;offerta assistenziale inserita in AReA e
		i nodi organizzativi degli organigrammi inseriti in POA prima di avviare un procedimento autorizzativo sono
		sottoposti a verifica di congruenza con quanto previsto dalla programmazione e inserito in Cronos (per le tipologie
		soggette a programmazione).</p><br>
	<p>Tramite integrazione con Cronos gli erogatori
		possono verificare la congruenza dell&rsquo;offerta assistenziale per la quale intendono avviare un procedimento
		autorizzativo con la programmazione regionale.</p><br>
	<p>Tramite integrazione con Cronos le Aziende
		sanitarie sono guidate all&rsquo;elaborazione di Atti aziendali che rispettano le regole di contenuto, di relazione
		e di quantit&agrave; previste dalla programmazione regionale per le Unit&agrave; Operative Complesse e Semplici e
		che danno attuazione al modello organizzativo regionale.</p><br>
	<p><span class="text-danger">ACCEDI a CRONOS (operatori)
			(pubblico)</span></p><br>
</ng-template>

<ng-template #prepsfDescTpl>
	<p>Le direttive di AIFA prevedono che i Prescrittori
		di Piani Terapeutici siano identificati dalle Regioni in base alla disciplina dell&rsquo;Unit&agrave; Operativa di
		appartenenza o in base alla specializzazione o in relazione ad altri criteri specifici.</p><br>
	<p>La piattaforma prePSF utilizza in modo integrato
		l&rsquo;Anagrafica della Rete Assistenziale <span class="text-highlight">AReA</span>, e gli Organigrammi aziendali
		(<span class="text-highlight">POA</span>). Gli organigrammi, nella Sezione Personale, riportano i dati dei medici,
		sia per le Aziende sanitarie (che li ricavano da HR) sia per le strutture private (accreditate e non) che li
		inseriscono direttamente.</p><br>
	<p>In questo modo, la piattaforma prePSF seleziona
		dinamicamente i medici che corrispondono ai criteri e che Regione Veneto intende autorizzare. Questo personale,
		accedendo alla piattaforma PSF, potr&agrave; compilare i Piani terapeutici di competenza, in base alle
		autorizzazioni ricevute.</p><br>
	<p>L&rsquo;applicativo gestir&agrave; le
		autorizzazioni dinamicamente in base al turn over del personale: i prescrittori in uscita dal contesto organizzativo
		per il quale erano stati autorizzati perderanno automaticamente l&rsquo;autorizzazione, i prescrittori in ingresso
		in contesti autorizzati potranno &ldquo;ereditare&rdquo; la stessa autorizzazione gi&agrave; rilasciata ai colleghi.
	</p><br>
	<p>Questa gestione semi-automatizzata richiede
		l&rsquo;utilizzo dell&rsquo;applicativo organigrammi anche da parte delle strutture private accreditate e non
		accreditate.</p><br>
	<p>L&rsquo;applicazione prevede che gli erogatori
		possano chiedere a Regione Veneto di estendere le autorizzazioni ad altro personale medico inizialmente non incluso.
	</p><br>
	<p><span class="text-danger">ACCEDI a prePSF
			(operatori)</span></p><br>
</ng-template>

<ng-template #autorizzazioniDescTpl>
	<p>L&rsquo;Anagrafica della Rete Assistenziale
		(AReA) comprende molte tipologie di Unit&agrave; di Offerta, la maggior parte delle quali soggette a Requisiti
		Specifici di <span class="text-highlight">Autorizzazione-Accreditamento </span>(LR 22/2002). L&rsquo;anagrafica
		delle attivit&agrave; gi&agrave; autorizzate costituisce il punto di partenza per i procedimenti di rinnovo
		dell&rsquo;autorizzazione (a scadenza) e per l&rsquo;inserimento delle nuove richieste di autorizzazione, il cui
		esito aggiorner&agrave; l&rsquo;anagrafica stessa in modo da dare ai diversi interlocutori il quadro aggiornato
		dell&rsquo;erogazione attiva e autorizzata.</p><br>
	<p>Un&rsquo;interfaccia specifica di AReA gestisce
		l&rsquo;<span class="text-highlight">Autorizzazione</span> <span class="text-highlight">dei prescrittori di Piani
			terapeutici </span>(prePSF).</p><br>
	<p>Sono inseriti in AReA sulla scorta di specifiche
		autorizzazioni, i <span class="text-highlight">Centri Regionali Specializzati</span>.</p><br>
	<p>Sono inseriti in AReA anche le <span class="text-highlight">Unit&agrave; di Offerta di natura sociale
		</span>implicitamente autorizzate in quanto
		soggette a specifici finanziamenti (ad es. le strutture &ldquo;Dopo di noi&rdquo;).</p><br>
	<p><span class="text-danger">ACCEDI a:</span>
	</p><br>
	<ul class="bullet-disc">
		<li>
			<p><span class="text-danger">Autorizzazioni LR
					22/2002 (operatori titolare)</span></p><br>
			<ul>
				<li>
					<p>Normativa</p><br>
					<ul>
						<li>
							<p>Elenco da AReA</p><br>
						</li>
					</ul>
				</li>
				<li>
					<p>Documenti</p><br>
					<ul>
						<li>
							<p>Classificazione LR 22</p><br>
						</li>
						<li>
							<p>Tipologie di UdO</p><br>
						</li>
						<li>
							<p>Requisiti</p><br>
						</li>
						<li>
							<p>UdO non AuAc</p><br>
						</li>
					</ul>
				</li>
				<li>
					<p>Report strutture
						sociosanitarie (validazione: processo di AuAc)</p><br>
				</li>
				<li>
					<p>Report strutture sanitarie
						(validazione: processo di AuAc)</p><br>
				</li>
			</ul>
		</li>

		<li>
			<p><span class="text-danger">Centri Regionali
					Specializzati (operatori titolare) (pubblico)</span></p><br>
			<ul>
				<li>
					<p>Normativa</p><br>
				</li>
				<li>
					<p>Centri Regionali
						specializzati per AUlss, titolare e UOC</p><br>
				</li>
			</ul>
		</li>

		<li>
			<p><span class="text-danger">Strutture Sociali
					(operatori titolare) (pubblico)</span></p><br>
			<ul>
				<li>
					<p>Tipologie di UdO Sociali</p><br>
				</li>
				<li>
					<p>Report strutture sociali per ambito,
						titolare e Ulss territoriale</p><br>
				</li>
			</ul>
		</li>

	</ul>
</ng-template>

<ng-template #argoDescTpl>
	<p>La micrologistica gestisce la distribuzione dei
		materiali dai magazzini centrali (farmaci, dispositivi medici, presidi sanitari, materiale di consumo&hellip;) su
		input delle Unit&agrave; Operative che li richiedono, alle Unit&agrave; di Offerta che li utilizzano, distribuzione
		comprensiva di uno &ldquo;scarico&rdquo; contabile alla consegna sulla struttura richiedente e/o di un successivo
		&ldquo;scarico&rdquo; contabile sulla struttura che ha in carico il paziente che utilizza il prodotto o al quale
		viene somministrato ad esempio il farmaco.</p><br>
	<p>Un&rsquo;interfaccia di AReA identifica le
		Unit&agrave; di Offerta che costituiscono <strong>punto di consegna</strong> e contestuale punto di prelievo (sono
		UdO monospecialistiche) e le Unit&agrave; di Offerta &ldquo;modulo&rdquo; che invece ricevono forniture per le
		diverse Unit&agrave; Operative che compongono il &ldquo;modulo&rdquo;. Quando richieste da una specifica
		Unit&agrave; Operativa (es. protesi ortopediche in Reparto Operatorio modulo) il punto di consegna coincider&agrave;
		con un punto di prelievo, quando la richiesta sar&agrave; stata effettuata in modo promiscuo (materiale di consumo
		comune) la determinazione dell&rsquo;entit&agrave; del prelievo da parte di ciascuna Unit&agrave; Operativa
		sar&agrave; gestito con altri criteri.</p><br>
	<p>I punti di prelievo coincidono o confluiscono in
		Centri di Costo, articolazioni dei Centri di Responsabilit&agrave; identificati nelle Unit&agrave; Operative
		Complesse (UOC).</p><br>
	<p>Il percorso di avvio del Sistema Informativo
		Ospedaliero (SIO) e il progetto regionale di riformulazione dei <strong>Centri di Costo</strong> su due livelli di
		progressiva analiticit&agrave; (regionale e aziendale) hanno condotto ad un primo livello di codifica comune tra le
		aziende: l&rsquo;associazione tra &ldquo;tripletta SIO&rdquo;
		(Unit&agrave;Operativa-Unit&agrave;diOfferta-Disciplina) e Centro di Costo.</p><br>
	<p>ACCEDI a ARGO (operatori)</p><br>
	<p>Report punti di Consegna</p><br>
	<p>Report Triplette Centri di
		Responsabilit&agrave; e Centri di Costo</p><br>
</ng-template>

<ng-template #replanDescTpl>
	<p>RePlan &egrave; il Repository delle Planimetrie delle strutture
		sanitarie e sociosanitarie censite nell&rsquo;anagrafica della Rete Assistenziale (AReA).</p><br>
	<p>Le Planimetrie sono elaborate e aggiornate dal personale tecnico,
		sono funzionali al Sistema Informativo Ospedaliero e al processo di Autorizzazione-Accreditamento, consentendo di
		visualizzare la collocazione delle attivit&agrave; nei diversi spazi, che devono essere idonei alla destinazione
		d&rsquo;uso.</p><br>
	<p>RePlan &egrave; accessibile alla Direzione Edilizia e FC di
		Regione Veneto e agli operatori regionali e aziendali coinvolti nei processi che necessitano dell&rsquo;accesso alle
		planimetrie.</p><br>
	<p>RePlan prevede la creazione in automatico dell&rsquo;architettura
		documentale secondo la struttura delle anagrafiche di AReA e quindi per titolare, sede operativa, edificio e piano;
		gestisce il versioning dei documenti e consente di classificarli, consultarli, scaricarli o allegarli ai
		procedimenti di interesse.</p><br>
	<p><span class="text-danger">ACCEDI a Replan (operatori)</span>
	</p><br>
	<p><span class="text-danger">CERCA</span></p><br>
</ng-template>


<ng-template #mappaDescTpl>
	<p>Utilizzando filtri di ricerca singolarmente o in modo combinato (inserire flag ricerca multipla) si accede
		all’elenco delle strutture/attività di interesse</p>
</ng-template>

<!--<div class="row bg-primary">-->
<!--  <h3>{{ item.name }}</h3>-->
<!--  <p>{{ item.desc }}</p>-->
<!--</div>-->
<!--<div class="row">-->
<!--  <div class="col-8">-->
<!--    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">-->
<!--      <li [ngbNavItem]="1">-->
<!--        <button ngbNavLink>Descrizione</button>-->
<!--        <ng-template ngbNavContent>-->
<!--          <p>{{ explanation }}-->
<!--          </p>-->
<!--        </ng-template>-->
<!--      </li>-->
<!--      <li [ngbNavItem]="2">-->
<!--        <button ngbNavLink>Screenshots</button>-->
<!--        <ng-template ngbNavContent>-->
<!--          <div *ngIf="screenshots$ | async as shots" class="row">-->
<!--            <div *ngFor="let shot of shots" class="col">-->
<!--              <img [src]="shot" class="img-fluid" alt="Fancy Screenshot!!!">-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </li>-->
<!--      <li [ngbNavItem]="3">-->
<!--        <button ngbNavLink>Dati</button>-->
<!--        <ng-template ngbNavContent>-->
<!--          <div *ngIf="data$ | async as data" class="row">-->
<!--            <div class="col">-->
<!--              <div *ngFor="let d of data" class="row">-->
<!--                <div class="col">-->
<!--                  <p>{{ d }}</p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </li>-->
<!--    </ul>-->

<!--    <div [ngbNavOutlet]="nav" class="mt-2"></div>-->

<!--  </div>-->
<!--  <div class="col-4">-->
<!--    <ul class="list-group list-group-flush">-->
<!--      <li class="list-group-item d-flex justify-content-between">-->
<!--        <h5>Versione</h5>-->
<!--        <span>Versione</span>-->
<!--      </li>-->
<!--      <li class="list-group-item d-flex justify-content-between">-->
<!--        <h5>Data di rilascio</h5>-->
<!--        <span>Data di rilascio</span>-->
<!--      </li>-->
<!--      <li class="list-group-item d-flex justify-content-between">-->
<!--        <h5>Ultimo aggiornamento</h5>-->
<!--        <span>Ultimo aggiornamento</span>-->
<!--      </li>-->
<!--    </ul>-->
<!--    <div class="row">-->
<!--      <div class="col gy-1 d-flex justify-content-center">-->
<!--        <a class="btn btn-lg btn-primary flex-grow-1 rounded-pill">Accedi</a>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="row">-->
<!--      <div class="col gy-1 d-flex justify-content-center">-->
<!--        <a class="btn btn-lg btn-primary flex-grow-1 rounded-pill">Manuale</a>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="row">-->
<!--      <div class="col gy-1 d-flex justify-content-center">-->
<!--        <a class="btn btn-lg btn-primary flex-grow-1 rounded-pill">Scarica OpenData</a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->